// based on this article: http://www.quirksmode.org/js/cookies.html
// manage cookies through javascript
// turned jquery plugin to avoid global namespace clutter

(function($){

  var cookieManager = $.cookieManager = {
    createCookie: function(name, value, days){
      var expires, host, domain, domainParts;

      if (days) {
        var date = new Date();

        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }

      host = location.host;

      if (host.split('.').length === 1) {
        document.cookie = name + "=" + value + expires + "; SameSite=Lax; path=/";

        return;
      }

      domainParts = host.split('.');
      domainParts.shift();
      domain = '.' + domainParts.join('.');

      if (cookieManager.readCookie(name) == null || cookieManager.readCookie(name) != value) {
        domain = '.' + host;
      }

      document.cookie = name + "=" + value + expires + "; path=/; SameSite=Lax; domain=" + domain;
    },
    readCookie: function(name){
      var nameWithEquals = name + "=";
      var cookieArray = document.cookie.split(';');
      var currentCookie;

      for (var i = 0; i < cookieArray.length; i++) {
        currentCookie = cookieArray[i];

        while (currentCookie.charAt(0) == ' ') {
          currentCookie = currentCookie.substring(1, currentCookie.length);
        }

        if (currentCookie.indexOf(nameWithEquals) == 0) {
          return currentCookie.substring(nameWithEquals.length, currentCookie.length);
        }
      }

      return null;

    },
    eraseCookie: function(name){
      cookieManager.createCookie(name,"",-1);
    }
  }

})(jQuery);
