root = exports ? this

class Requireable

  constructor: (script, options={}) ->
    @script = script
    @options = options

    @timeout = options.timeout or 30

    @var_timeout = @timeout

    @requested = no
    @loaded = no
    @attempts = 0
    @xhr = null

    @deferred = $.Deferred()

    # requires inside this script
    @required_dependencies = []
    # declared in config
    @declared_dependencies = options.dependencies or []

    @declared_extensions = options.extensions or []

  load: () ->
    if @declared_dependencies.length
      # Load pre configured dependencies.
      # They must load before the script itself
      @load_dependencies()
    else
      @_load()

    @deferred.promise()

  _success: ->
    rm = RequireManager
    @options.afterCallback?()
    # requires called inside the loaded script must resolve before
    $.when.apply(@, @required_dependencies).done =>
      # requires extensions after load
      if @declared_extensions.length
        rm.require @declared_extensions, =>
          @deferred.resolve()
      else
        @deferred.resolve()
    .fail =>
      @_fail "#{@script} could not be loaded due dependencies fail"

  _fail: (msg, reset = no) ->
    if @deferred.state() is 'pending'
      RequireManager.error msg
      @deferred.reject(msg)
      @options.failCallback?()
      if reset
        # Reset for posterior calls
        @var_timeout = @timeout
        @attempts = 0
        @deferred = $.Deferred()

  _load: () ->
    unless @requested
      rm = RequireManager
      @requested = yes
      @attempts += 1

      if @attempts is 1
        @options.beforeCallback?()

      @xhr = $.ajax
        url: @script,
        dataType: "script",
        async: not (QB.isTestEnv or rm.sync_mode)
        cache: true,
        crossDomain: true,
        success: =>
          @loaded = yes
          @_success()

      if @timeout > 0
        # jQuery load script doesn't call error callback, so
        # If not loaded after @timeout seconds...
        setTimeout =>
          if (not @loaded) and @xhr.state() is 'pending'
            @xhr.abort()
            @requested = no
            if @attempts < rm.max_attempts
              @var_timeout = @timeout * (@attempts + 1)
              @_load()
            else
              @_fail "#{@script} could not be loaded (timeout)", yes
        , @var_timeout * 1000

  load_dependencies: ->
    RequireManager.require @declared_dependencies, =>
      @_load()
    , =>
      @_fail "#{@script} could not be loaded due dependencies fail"

  add_dependency: (prom) ->
    @required_dependencies.push(prom)


# Just to enable RequireManager.require('jquery')
class JqRequireable extends Requireable

  load: () ->
    @deferred.resolve()
    @deferred.promise()


class RequireManager

  # Registered objects
  @objects: {}
  # active loads
  @active: 1
  # for browser that not suport script_filenam
  @sync_mode: off
  # max attempts to load
  @max_attempts: 3
  ###
    name: [An alias to use in require later]
    script: [script url]
    options =
      [To be executed before script loads]
      beforeCallback: () ->
      [To be executed after script loads first time]
      afterCallback: () ->
      [Time to wait script load before fire error (in seconds)]
      timeout: 10
      [Dependencies to be loaded before script loads]
      dependencies: []
      [Extensions to be loaded after script loads but before resolve]
      extensions: []
      [If true, the script will load as soon as possible. no require needed]
      autoload: false
  ###

  @init: ->
    rm = RequireManager

    if $.browser?.msie and $.browser?.versionNumber is 9
      rm.sync_mode = on

    rm.filename = rm.script_filename()

    rm.collect_early()

    # self
    rm.active -= 1

  @collect_early: () ->
    rm = RequireManager
    # It will not be loaded for real. It should be already loaded.
    rm.objects['jquery'] = new JqRequireable('jquery_fakeurl.js')

    # Applies early registered/required scripts
    while window._rg.length
      rm.register.apply(@, window._rg.shift())

    while window._rq.length
      rm.require.apply(@, window._rq.shift())

    while window._ros.length
      rm.require_on_show.apply(@, window._ros.shift())

  @register: (name, script, options) ->
    rm = RequireManager

    unless rm.objects[name]?
      req = new Requireable(script, options)
      rm.objects[name] = req

      if options?.autoload
        rm.require name, ->

  @require: (names, callback, fail, always) ->
    rm = RequireManager

    unless names instanceof Array
      names = [names]

    rm.resolve_dependencies(names)

    proms = []

    for name in names
      unless rm.objects[name]?
        rm.error "Unregistered script #{name}"
        rm.active -= proms.length
        fail?()
        always?()
        return

      rm.active += 1
      proms.push rm.objects[name].load()

    $.when.apply(@, proms).done(callback).fail (msg) ->
      fail?()
    .always ->
      rm.active -= proms.length
      always?()

  # These scripts will be required only when el appears in screen
  @require_on_show: (names, el, callback) ->
    rm = RequireManager

    must_show = ->
      if $(el).length
        w = $(window)
        [wh, ww] = [w.height(), w.width()]
        [st, sl] = [w.scrollTop(), w.scrollLeft()]
        os = $(el).offset()

        if (st + wh > os.top) and (ww + sl > os.left) and $(el).is(':visible')
          rm.require names, callback
          return

      setTimeout ->
        $(window).one 'scroll', must_show
      , 500

    must_show()

  # If a require call is made inside the loaded script we must add
  # this required script as dependency of loaded script and execute
  # the loaded script callback only after the dependency script is
  # loaded too. This method get script filename and search for it
  # in registered scripts to add the appropriate dependency
  @resolve_dependencies: (names) ->
    rm = RequireManager

    dependent_script = rm.script_filename().trim()
    unless dependent_script in [rm.filename, '']
      for k, obj of rm.objects
        if obj.script.indexOf(dependent_script) > -1
          for name in names
            obj.add_dependency rm.objects[name].deferred.promise()

  @error: (msg) ->
    if QB.isProdEnv
      Rollbar?.warning?(msg)

    console?.warn?(msg)

  # return the filename of current executing script
  @script_filename: () ->
    line = ''

    if RequireManager.sync_mode
      return line
    # some browsers does not have this feature :/
    if document.currentScript?
      line = document.currentScript.src
    else
      # Oh, yeah, baby!
      try
        throw new Error('x')
      catch e
        if e.stack?.length
          lines = e.stack.split('\n')
          if lines.length
            line = lines[lines.length - 1]

    matches = line.match(/([^\/]+(\.js)?)((\:\d+){2})?$/gi)
    if matches?.length
      return matches[0].split(':')[0]

    return ''


RequireManager.init()
# a shortcut :D
window._require = RequireManager.require
window._require_on_show = RequireManager.require_on_show
